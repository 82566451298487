<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top:50px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">报事报修</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">报事报修管理解决方案</p>
      </div>

      <div class="Photo">
        <div>
          <el-image
            style="width: 197px; height: 257px"
            :src="require('../assets/platformp/platform.png')"
          ></el-image>
        </div>
        <div class="r">
          <p class="r-t">基于平台 打造在线应用</p>
          <p class="r-b">
            业主可在手机上直接发起报事报修，客服可通过电脑或者手机对工单进行回复、转交，同时可追踪每个工单的进度、状态、评价，轻松打造物业
          </p>
        </div>
      </div>

      <div class="model">
        <div class="box">
          <p class="box-title">一、智能分配 对接快捷</p>
          <div class="content">
            <div class="l">
              <p class="t">Intelligent distribution and convenient docking</p>
              <p class="lt">智能分配 便捷对接</p>
            </div>
            <div class="r">
              <el-image
                style="width: 247px; height: 257px"
                :src="require('../assets/platformp/platform-1.png')"
              ></el-image>
            </div>
          </div>
          <p class="box-bottom">
            报修工单上传后台后，后台智能筛选并分配给符合条件的维修人员，省去中间调节环节，染报修高效准确便捷
          </p>
        </div>

        <div class="box">
          <p class="box-title">一、智能分配 对接快捷</p>
          <div class="content reserve">
            <div class="l">
              <p class="t">Intelligent distribution and convenient docking</p>
              <p class="lt">智能分配 便捷对接</p>
            </div>
            <div class="r">
              <el-image
                style="width: 237px; height: 247px"
                :src="require('../assets/platformp/platform-2.png')"
              ></el-image>
            </div>
          </div>
          <p class="box-bottom">
            报修工单上传后台后，后台智能筛选并分配给符合条件的维修人员，省去中间调节环节，染报修高效准确便捷
          </p>
        </div>

        <div class="box" style="margin-bottom:60px">
          <p class="box-title">一、智能分配 对接快捷</p>
          <div class="content">
            <div class="l">
              <p class="t">Intelligent distribution and convenient docking</p>
              <p class="lt">智能分配 便捷对接</p>
            </div>
            <div class="r">
              <el-image
                style="width: 247px; height: 247px"
                :src="require('../assets/platformp/platform-3.png')"
              ></el-image>
            </div>
          </div>
          <p class="box-bottom">
            报修工单上传后台后，后台智能筛选并分配给符合条件的维修人员，省去中间调节环节，染报修高效准确便捷
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zoomValue: 0,
    };
  },
  created() {},
  mounted() {
    var that = this;
    var browerWidth = window.innerWidth; //浏览器可视宽度
    var baseWidth = 1920; //设计稿宽度
    var zoomValue = browerWidth / baseWidth; //缩放比例计算
    document.getElementById("page").style.transform =
      "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
    window.onresize = function () {
      //窗口尺寸变化时，重新计算和缩放
      browerWidth = window.innerWidth;
      zoomValue = browerWidth / baseWidth;
      that.setval();
      document.getElementById("page").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")";
    };
  },
  methods: {
    setval() {
      this.zoomValue = Math.random();
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  // display: inline-block;
  // width: 1920px;
  // transform-origin: 0 0;
  // position: absolute;
  // left: 0;
  text-align: center;
  .mine {
    width: 60%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .Photo {
    margin-top: 130px;
    height: 257px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    p {
      margin: 0;
    }
    .r {
      height: 100%;
      width: 50%;
      text-align: left;
      .r-t {
        font-weight: 400;
        color: #333333;
        font-size: 33px;
      }
      .r-b {
        margin-top: 41px;
        font-weight: 400;
        color: #333333;
        line-height: 27px;
        font-size: 20px;
      }
    }
  }

  .model {
    text-align: left;
    margin-top: 130px;
    .box {
      .box-title {
        font-weight: bold;
        color: #333333;
        font-size: 24px;
      }
      .content {
        width: 100%;
        height: 250px;
        background: linear-gradient(90deg, #cfffdf 0%, #ffffff 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 30px 0 30px 100px;
        p {
          margin: 0;
        }
        .l {
          .t {
            font-size: 20px;
            font-weight: 400;
            color: #3ca35f;
          }
          .lt {
            margin-top: 20px;
            font-size: 49px;
            font-weight: bold;
            color: #3ca35f;
          }
        }
      }
      .reserve {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        background: linear-gradient(-90deg, #cfffdf 0%, #ffffff 100%);
        padding: 30px 100px 30px 0px;
      }
      .box-bottom {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
